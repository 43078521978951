import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { useFonts } from '@folklore/fonts';

import * as AppPropTypes from '../../lib/PropTypes';
import useWindowSize from '../../lib/useWindowSize';
import { setSize as setSizeActions } from '../../actions/LayoutActions';

import styles from '../../../styles/layouts/main.scss';

const propTypes = {
    children: PropTypes.node.isRequired,
    setSize: PropTypes.func.isRequired,
    isPrerender: PropTypes.bool,
    fullscreen: PropTypes.bool,
    fonts: AppPropTypes.fonts,
};

const defaultProps = {
    isPrerender: false,
    fullscreen: false,
    fonts: {
        google: {
            families: ['Open Sans:400,700'],
        },
        custom: {},
    },
};

const MainLayout = ({ fonts, children, setSize, isPrerender, fullscreen }) => {
    const { loaded: fontsLoaded } = useFonts(fonts);
    useWindowSize({
        onChange: size => {
            setSize(size);
        },
    });

    const innerStyle = {
        opacity: fontsLoaded || isPrerender ? 1 : 0,
    };

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.fullscreen]: fullscreen,
                },
            ])}
        >
            <div className={styles.inner} style={innerStyle}>
                <div className={styles.content}>{children}</div>
            </div>
        </div>
    );
};

MainLayout.propTypes = propTypes;
MainLayout.defaultProps = defaultProps;

const WithStateContainer = connect(
    ({ layout, site }) => ({
        size: layout.size,
        isPrerender: site.isPrerender || false,
    }),
    dispatch => ({
        setSize: size => dispatch(setSizeActions(size)),
    }),
)(MainLayout);

export default WithStateContainer;
