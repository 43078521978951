/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import * as AppPropTypes from '../../lib/PropTypes';
import useFacebookSDK from '../../lib/useFacebookSDK';
import NotFoundPage from './NotFound';
import SiteMeta from '../partials/SiteMeta';
import Site from '../partials/Site';

import styles from '../../../styles/pages/site.scss';

const propTypes = {
    intl: AppPropTypes.intl.isRequired,
    facebookAppId: PropTypes.string.isRequired,
    site: AppPropTypes.site,
};

const defaultProps = {
    site: null,
};

const SitePage = ({ intl, facebookAppId, site }) => {
    if (site === null) {
        return (
            <NotFoundPage />
        );
    }
    const { meta = null, ...siteProps } = site;

    const { FB } = useFacebookSDK({
        appId: facebookAppId,
        locale: intl.locale === 'en' ? 'en_US' : 'fr_CA',
    });
    useEffect(() => {
        if (FB !== null) {
            FB.AppEvents.logPageView();
        }
    }, [FB]);

    return (
        <div className={styles.container}>
            <SiteMeta {...meta} />
            <div className={styles.theme}>
                <Site site={siteProps} />
            </div>
        </div>
    );
};

SitePage.propTypes = propTypes;
SitePage.defaultProps = defaultProps;

const WithStateContainer = connect(({ site }) => ({
    facebookAppId: site.facebookAppId,
}))(SitePage);
const WithIntlContainer = injectIntl(WithStateContainer);

export default WithIntlContainer;
