export const pascalCase = str => str
    .replace(/[^a-z0-9]+/gi, ' ')
    .replace(/(\w)(\w*)/g, (g0, g1, g2) => `${g1.toUpperCase()}${g2.toLowerCase()}`)
    .replace(/\s+/gi, '');

export const getSiteUrlFromLocation = (
    { pathname = window.location.pathname } = {},
    { params: { site = null } = {} } = {},
    urlGenerator,
) => (site !== null
    ? urlGenerator.route('sites.show', {
        site,
    })
    : pathname);

// prettier-ignore
export const getSiteFromLocation = (pages, location, match, urlGenerator) => (
    pages[getSiteUrlFromLocation(location, match, urlGenerator)] || null
);
