import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: '#CC0000',
    className: null,
};

const FolkloreIcon = ({ className, color }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="11px"
        height="16px"
        viewBox="0 0 11 16"
        xmlSpace="preserve"
        className={className}
    >
        <polygon
            fill="none"
            stroke={color}
            strokeMiterlimit="10"
            points="0.5,0.5 0.5,15.5 5.5,15.5 5.5,10.5 8,10.5 8,5.5 10.5,5.5 10.5,0.5 "
        />
    </svg>
);

FolkloreIcon.propTypes = propTypes;
FolkloreIcon.defaultProps = defaultProps;

export default React.memo(FolkloreIcon);
