/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { defineMessages, FormattedHTMLMessage } from 'react-intl';

import * as AppPropTypes from '../../lib/PropTypes';
import FolkloreIcon from '../icons/Folklore';

import styles from '../../../styles/partials/credits.scss';

export const messages = defineMessages({
    main: {
        id: 'content.main_credits',
        defaultMessage:
            '<strong>folklore.quebec</strong>, un site de <a href="https://folkloreinc.ca">Folklore</a>',
    },
    site: {
        id: 'content.site_credits',
        defaultMessage:
            'Ce site a été créé avec <a href="https://folklore.quebec">folklore.quebec</a>',
    },
});

const propTypes = {
    label: AppPropTypes.label,
    className: PropTypes.string,
    withoutIcon: PropTypes.bool,
};

const defaultProps = {
    label: messages.site,
    className: null,
    withoutIcon: false,
};

const Credits = ({ label, className, withoutIcon }) => (
    <div
        className={classNames([
            styles.container,
            {
                [className]: className !== null,
            },
        ])}
    >
        {!withoutIcon ? (
            <FolkloreIcon className={styles.icon} />
        ) : null}
        <span className={styles.label}>
            <FormattedHTMLMessage tagName="span" {...label} />
        </span>
    </div>
);

Credits.propTypes = propTypes;
Credits.defaultProps = defaultProps;

export default React.memo(Credits);
