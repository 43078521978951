import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { UrlGeneratorProvider, createStore } from '@folklore/react-container';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';

import reducers from '../reducers/index';
import * as AppPropTypes from '../lib/PropTypes';

import App from './App';

const propTypes = {
    locale: PropTypes.string,
    messages: PropTypes.oneOfType([
        PropTypes.objectOf(PropTypes.objectOf(PropTypes.string)),
        PropTypes.objectOf(PropTypes.string),
    ]),
    routes: PropTypes.objectOf(PropTypes.string),
    statusCode: AppPropTypes.statusCode,
    site: AppPropTypes.site,
    isPrerender: PropTypes.bool,
    facebookAppId: PropTypes.string,
    facebookPageId: PropTypes.string,
};

const defaultProps = {
    locale: 'fr',
    messages: {},
    routes: {},
    statusCode: null,
    site: null,
    isPrerender: false,
    facebookAppId: null,
    facebookPageId: null,
};

const Root = ({
    locale,
    messages,
    routes,
    statusCode,
    site,
    isPrerender,
    facebookAppId,
    facebookPageId,
}) => {
    const store = useMemo(
        () =>
            createStore(reducers, {
                site: {
                    isPrerender,
                    facebookAppId,
                    facebookPageId,
                    sites:
                        site !== null
                            ? {
                                  [site.url]: site,
                              }
                            : {},
                },
            }),
        [statusCode, site, isPrerender, facebookAppId, facebookPageId],
    );
    return (
        <ReduxProvider store={store}>
            <IntlProvider locale={locale} messages={messages[locale] || messages}>
                <BrowserRouter>
                    <UrlGeneratorProvider routes={routes}>
                        <App />
                    </UrlGeneratorProvider>
                </BrowserRouter>
            </IntlProvider>
        </ReduxProvider>
    );
};

Root.propTypes = propTypes;
Root.defaultProps = defaultProps;

export default Root;
