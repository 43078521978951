import Loadable from 'react-loadable';

export const Celine = Loadable({
    loader: () => import('./Celine'),
    loading: () => null,
});

export const Trudeau = Loadable({
    loader: () => import('./Trudeau'),
    loading: () => null,
});

export const LangueDeBois = Loadable({
    loader: () => import('./langue-de-bois/LangueDeBois'),
    loading: () => null,
});
