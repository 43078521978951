/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
// import PropTypes from 'prop-types';

import * as AppPropTypes from '../../lib/PropTypes';
import { pascalCase } from '../../lib/utils';
import SiteContext from '../../lib/SiteContext';
import * as Themes from '../themes';
import * as Microsites from '../microsites';

const propTypes = {
    site: AppPropTypes.site,
};

const defaultProps = {
    site: null,
};

const Site = ({ site }) => {
    const { type = 'base', theme = 'travel', microsite = null, ...siteProps } = site;
    if (type === 'microsite') {
        const Microsite = Microsites[pascalCase(microsite)] || null;
        return Microsite !== null ? (
            <SiteContext.Provider value={site}>
                <Microsite {...siteProps} />
            </SiteContext.Provider>
        ) : null;
    }
    const Theme = Themes[pascalCase(theme)] || null;
    return Theme !== null ? (
        <SiteContext.Provider value={site}>
            <Theme {...siteProps} />
        </SiteContext.Provider>
    ) : null;
};

Site.propTypes = propTypes;
Site.defaultProps = defaultProps;

export default Site;
