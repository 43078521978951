import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Route, Switch } from 'react-router';
import { connect } from 'react-redux';
import { withUrlGenerator } from '@folklore/react-container';

import * as AppPropTypes from '../lib/PropTypes';
import { getSiteFromLocation } from '../lib/utils';
import MainLayout from './layouts/Main';
import HomePage from './pages/Home';
import SitePage from './pages/Site';
import NotFound from './pages/NotFound';

import '../../styles/main.global.scss';

const propTypes = {
    urlGenerator: AppPropTypes.urlGenerator.isRequired,
    location: PropTypes.string,
    site: AppPropTypes.site,
};

const defaultProps = {
    location: null,
    site: null,
};

const App = ({ urlGenerator, location, site }) => {
    const homePath = urlGenerator.route('home');
    const isHome = homePath === location;
    return (
        <MainLayout site={site} fullscreen={isHome}>
            <Switch>
                <Route exact path={homePath} component={HomePage} />
                <Route
                    exact
                    path={urlGenerator.route('sites.show')}
                    render={() => <SitePage site={site} />}
                />
                <Route path="*" render={() => <NotFound />} />
            </Switch>
        </MainLayout>
    );
};

App.propTypes = propTypes;
App.defaultProps = defaultProps;

const WithStateContainer = connect(
    ({ site }) => ({
        sites: site.sites,
    }),
    null,
    ({ sites, ...stateProps }, dispatchProps, {
        location, match, urlGenerator, ...ownProps
    }) => ({
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        urlGenerator,
        location: location.pathname,
        site: getSiteFromLocation(sites, location, match, urlGenerator),
    }),
)(App);
const WithUrlGeneratorContainer = withUrlGenerator(WithStateContainer);
const WithRouterContainer = withRouter(WithUrlGeneratorContainer);
export default WithRouterContainer;
