/* globals FB: true */
import { useRef, useState, useEffect } from 'react';
import { loadFacebook } from '@folklore/services';

const useFacebookSdk = (opts = {}) => {
    const FBRef = useRef(typeof FB !== 'undefined' ? FB : null);
    const [loaded, setLoaded] = useState(false);

    const optsKeys = Object.keys(opts).sort().reduce((map, key) => ([
        ...map,
        key,
        opts[key],
    ]), []);

    useEffect(() => {
        let canceled = false;
        loadFacebook(opts).then((FB) => {
            if (canceled) {
                return;
            }
            FBRef.current = FB;
            setLoaded(true);
        });
        return () => {
            FBRef.current = null;
            canceled = true;
        };
    }, optsKeys);

    return {
        loaded,
        FB: FBRef.current,
    };
};

export default useFacebookSdk;
