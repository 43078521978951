/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    injectIntl, defineMessages, FormattedMessage, FormattedHTMLMessage,
} from 'react-intl';
import { connect } from 'react-redux';

import * as AppPropTypes from '../../lib/PropTypes';
import useFacebookSDK from '../../lib/useFacebookSDK';
import SiteMeta from '../partials/SiteMeta';
import Credits, { messages as creditsMessages } from '../partials/Credits';

import styles from '../../../styles/pages/home.scss';

const messages = defineMessages({
    title: {
        id: 'content.home_title',
        defaultMessage: 'Créez un site grâce à notre robot conversationnel',
    },
    explanation: {
        id: 'content.home_explanation',
        defaultMessage:
            'Pour créer un site, il vous suffit de dire<br/>«Bonjour Robot, je veux un site»<br/>sur notre <a href="https://m.me/Folkloreatelier">Messenger</a>.',
    },
});

const propTypes = {
    intl: AppPropTypes.intl.isRequired,
    facebookAppId: PropTypes.string.isRequired,
    facebookPageId: PropTypes.string.isRequired,
};

const defaultProps = {};

const HomePage = ({ intl, facebookAppId, facebookPageId }) => {
    const buttonRef = useRef(null);
    const { FB } = useFacebookSDK({
        appId: facebookAppId,
        locale: intl.locale === 'en' ? 'en_US' : 'fr_CA',
    });
    useEffect(
        () => {
            if (FB !== null && buttonRef.current !== null) {
                FB.XFBML.parse(buttonRef.current);
            }
        },
        [FB, buttonRef.current],
    );
    useEffect(
        () => {
            if (FB !== null) {
                FB.AppEvents.logPageView();
            }
        },
        [FB],
    );

    return (
        <div className={styles.container}>
            <SiteMeta
                title={intl.formatMessage({
                    id: 'meta.title',
                })}
                description={intl.formatMessage({
                    id: 'meta.description',
                })}
            />
            <div className={styles.inner}>
                <div className={styles.titleContainer}>
                    <h1 className={styles.title}>
                        <FormattedMessage {...messages.title} />
                    </h1>
                </div>
                <div className={styles.buttonContainer} ref={buttonRef}>
                    <div className={styles.button}>
                        <div
                            className="fb-messengermessageus"
                            messenger_app_id={facebookAppId}
                            page_id={facebookPageId}
                            data-color="blue"
                            data-size="xlarge"
                        />
                    </div>
                </div>
                <div className={styles.textContainer}>
                    <div className={styles.text}>
                        <FormattedHTMLMessage tagName="span" {...messages.explanation} />
                    </div>
                </div>
                <div className={styles.creditsContainer}>
                    <div className={styles.credits}>
                        <Credits label={creditsMessages.main} />
                    </div>
                </div>
            </div>
            <div className={styles.robot} />
        </div>
    );
};

HomePage.propTypes = propTypes;
HomePage.defaultProps = defaultProps;

const WithMemoContainer = React.memo(HomePage);
const WithStateContainer = connect(({ site }) => ({
    facebookAppId: site.facebookAppId,
    facebookPageId: site.facebookPageId,
}))(WithMemoContainer);
const WithIntlContainer = injectIntl(WithStateContainer);

export default WithIntlContainer;
