import PropTypes from 'prop-types';

/**
 * Core
 */
export const urlGenerator = PropTypes.shape({
    route: PropTypes.func.isRequired,
});

export const location = PropTypes.shape({
    pathname: PropTypes.string.isRequired,
});

export const intl = PropTypes.shape({
    locale: PropTypes.string.isRequired,
    formatMessage: PropTypes.func.isRequired,
});

export const message = PropTypes.shape({
    id: PropTypes.string.isRequired,
    defaultMessage: PropTypes.string,
});

export const label = PropTypes.oneOfType([
    message,
    PropTypes.node,
]);

export const fonts = PropTypes.shape({
    google: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
    custom: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
});

export const statusCode = PropTypes.oneOf([401, 403, 404, 500]);

/**
 * Elements
 */
export const media = PropTypes.shape({
    type: PropTypes.string,
    url: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

export const medias = PropTypes.arrayOf(media);

export const style = PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]));

export const favicon = PropTypes.shape({
    url: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
});

export const meta = PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    url: PropTypes.string,
    image: PropTypes.string,
    favicon,
});

/**
 * Site
 */
export const site = PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    subject: PropTypes.string,
    theme: PropTypes.string,
    color: PropTypes.string,
    meta,
    medias,
});

export const sites = PropTypes.arrayOf(site);
