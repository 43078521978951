import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import * as AppPropTypes from '../../lib/PropTypes';

const propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    url: PropTypes.string,
    image: PropTypes.string,
    favicon: AppPropTypes.favicon,
    children: PropTypes.node,
};

const defaultProps = {
    title: null,
    description: null,
    url: `${window.location.href}`,
    image: `${window.location.protocol}//${window.location.host}/img/facebook_share.jpg`,
    favicon: {
        url: '/favicon.png',
        type: 'image/png',
    },
    children: null,
};

const SiteMeta = ({ title, description, url, image, favicon, children }) => (
    <Helmet>
        <title>{`${title} - Folklore.Quebec`}</title>
        {description !== null ? <meta name="description" content={description} /> : null}
        <meta property="og:title" content={title} />
        {description !== null ? <meta property="og:description" content={description} /> : null}
        <meta property="og:url" content={url} />
        {image !== null ? <meta property="og:image" content={image} /> : null}
        {favicon !== null ? <link rel="icon" href={favicon.url} type={favicon.type} /> : null}
        {children}
    </Helmet>
);

SiteMeta.propTypes = propTypes;
SiteMeta.defaultProps = defaultProps;

export default React.memo(SiteMeta);
