import Loadable from 'react-loadable';

export const Artsy = Loadable({
    loader: () => import('./Artsy'),
    loading: () => null,
});

export const Communist = Loadable({
    loader: () => import('./Communist'),
    loading: () => null,
});

export const Construction = Loadable({
    loader: () => import('./Construction'),
    loading: () => null,
});

export const Game8bit = Loadable({
    loader: () => import('./Game8bit'),
    loading: () => null,
});

export const Geek = Loadable({
    loader: () => import('./Geek'),
    loading: () => null,
});

export const Love = Loadable({
    loader: () => import('./Love'),
    loading: () => null,
});

export const Quebec = Loadable({
    loader: () => import('./Quebec'),
    loading: () => null,
});

export const Thunder = Loadable({
    loader: () => import('./Thunder'),
    loading: () => null,
});

export const Travel = Loadable({
    loader: () => import('./Travel'),
    loading: () => null,
});
